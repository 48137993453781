/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Layout } from '../components';
import * as queryString from 'query-string';
import { locNavigate, updateReceiptHero, useUser } from '../state/session';
import { isBrowser } from '../utils';
import Spinner from '../components/Spinner';
import * as analytics from '../utils/analytics';
import * as api from '../utils/api';
import { useTranslate } from '../utils/getLanguage';

export default ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const translate = useTranslate();
  const dispatch = useDispatch();
  const user = useUser();
  const search = isBrowser ? window.location.search : '';
  const { code, state, error: initialError, error_description: initialDescription } = queryString.parse(search);
  const [error, setError] = useState(initialError);
  const [error_description, setErrorDescription] = useState(initialDescription);
  const [isDone, setDone] = useState(false);

  useEffect(() => {
    if (initialError === 'access_denied' && (initialDescription || '').includes('cancelled')) {
      dispatch(locNavigate('/my-account'));
      return;
    }
    if (!user || !code || !state || isDone) return;
    (async () => {
      const redirect_uri = window.location.origin + window.location.pathname;
      try {
        await api.finishReceiptHeroAuthentication(code, state, redirect_uri);
        setDone(true);
        dispatch(updateReceiptHero(true));
        dispatch(locNavigate('/my-account'));
      } catch (error) {
        setError(translate('account.receiptHero.failed'));
        setErrorDescription(error.message || '' + error);
      }
    })();
  }, [initialError, initialDescription, user, code, state, isDone, dispatch, translate]);

  return (
    <Layout paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container variant="parcel" sx={{ py: [3, 4] }}>
        <Box sx={{ position: 'relative', minHeight: 400 }}>
          {!isBrowser ? null : error ? (
            <Box>
              {error}: {error_description}
            </Box>
          ) : (
            <Spinner size="medium" />
          )}
        </Box>
      </Container>
    </Layout>
  );
};
